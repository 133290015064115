import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Form1 } from '../../components/Form';
import { Input1, Option, Select, Textarea } from  '../../components/Input';
import url from '../../url';
import useAuth from '../../hooks/useAuth';
import { useEffect } from 'react';
import { Alert2, AlertContainer } from '../../components/Alert';

function EditTodo() {
  const [value, setValue] = useState({title:'', description:'', note:'', name:'', email:'', phone:'', address:'', requirement:'', price:0, cupdate:'',contactDate:'', workDate:'', workStatus:'', contactStatus:'', addBy:''});
  const [alert, setAlert] = useState([]);
  const navigate = useNavigate();
  const { tid } = useParams();
  const { id } = useAuth();

  useEffect(()=>{

    setValue({...value,"workerId":id});

  },[id]);

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  useEffect(()=>{
    fetch(`${url}/workTodo/ws/${tid}`,{
      method:"GET",
      credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{

      const d1 = new Date(data.data.contactDate);
      const d2 = new Date(data.data.workDate);

      setValue({title:data.data.title, description:data.data.description, note:data.data.note, name:data.data.name, email:data.data.email, phone:data.data.phone, address:data.data.address,workStatus:data.data.workStatus, requirement:data.data.requirement, price:data.data.price, cupdate:data.data.cupdate,contactDate:d1.toLocaleDateString('en-CA'), workDate:d2.toLocaleDateString('en-CA'),contactStatus:data.data.contactStatus, addBy:data.data.addBy});
    })

  },[tid])


  function send(){

    fetch(`${url}/workTodo/${tid}`,{
      method:"PUT",
      body: JSON.stringify(value),
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{

      if(data.status === true){
        navigate(-1);
      }else{
        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
      }
    });


  }


  return (
    <Form1 title="Edit Todo">
        <div className= " fixed top-16 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <Input1 onChange={set} name="title" type="text" placeholder="Enter work title:" value={value.title} lavel="Work title:" />
        <Textarea onChange={set} name="description" placeholder="Enter work description:" value={value.description} lavel="Work description:" />
        <Textarea onChange={set} name="note" placeholder="Enter work note:" value={value.note} lavel="Work note:" />
        <Input1 onChange={set} name="name" type="text" placeholder="Enter client name:" value={value.name} lavel="Client name:" />
        <Input1 onChange={set} name="email" type="text" placeholder="Enter client email:" value={value.email} lavel="Client email:" />
        <Input1 onChange={set} name="phone" type="text" placeholder="Enter client phone number:" value={value.phone} lavel="Client phone number:" />
        <Textarea onChange={set} name="address" placeholder="Enter work address:" value={value.address} lavel="Client address:" />
        <Textarea onChange={set} name="requirement" placeholder="Enter client requirement:" value={value.requirement} lavel="Client requirement:" />
        <Input1 onChange={set} name="price" type="number" placeholder="Enter price for client:" value={value.price} lavel="Price for client:" />
        <Input1 onChange={set} name="cupdate" type="text" placeholder="Enter client update:" value={value.cupdate} lavel="Client update:" />
        <Input1 onChange={set} name="contactDate" type="date" placeholder="Enter client contact date:" value={value.contactDate} lavel="Contact date:" />
        <Input1 onChange={set} name="workDate" type="date" placeholder="Enter working date:" value={value.workDate} lavel="Working date:" />

        <Select onChange={set} name="contactStatus" value={value.contactStatus} lavel="Contact Status" >
          <Option value="pending">Pending</Option>
          <Option value="contacted">Contacted</Option>
          <Option value="cancel">Cancel</Option>
        </Select>
        <Select onChange={set} name="workStatus" value={value.workStatus} lavel="Work Status" >
          <Option value="not goted">Not Goted</Option>
          <Option value="goted">Goted</Option>
          <Option value="done">Done</Option>
          <Option value="cancel">cancel</Option>
        </Select>

        <Input1 onChange={set} name="addBy" type="text" placeholder="Enter added by:" value={value.addBy} lavel="Added by:" />

        <Button1 click={send} name="Save" />
    </Form1>

  )
}

export default EditTodo;