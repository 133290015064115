import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Form1 } from '../../components/Form';
import { Input1, Textarea } from  '../../components/Input';
import url from '../../url';
import useAuth from '../../hooks/useAuth';
import { useEffect } from 'react';
import { Alert2, AlertContainer } from '../../components/Alert';


function AddTodo() {
  const [value, setValue] = useState({title:'', description:'', note:'', name:'', email:'', phone:'', address:'', requirement:'', price:0, cupdate:'',contactDate:'', workDate:'', addBy:''});
  const navigate = useNavigate();
  const [alert, setAlert] = useState([]);
  const { id } = useAuth();

  useEffect(()=>{

    setValue({...value,"workerId":id});

  },[id]);

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  function send(){

    fetch(`${url}/workTodo`,{
      method:"POST",
      body: JSON.stringify(value),
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{

      if(data.status === true){
        navigate(-1);
      }else{
        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
      }
    });


  }


  return (
    <Form1 title="Work Todo">
        <div className= " fixed top-16 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <Input1 onChange={set} name="title" type="text" placeholder="Enter work title:" value={value.title} lavel="Work title:" />
        <Textarea onChange={set} name="description" placeholder="Enter work description:" value={value.description} lavel="Work description:" />
        <Textarea onChange={set} name="note" placeholder="Enter work note:" value={value.note} lavel="Work note:" />
        <Input1 onChange={set} name="name" type="text" placeholder="Enter client name:" value={value.name} lavel="Client name:" />
        <Input1 onChange={set} name="email" type="text" placeholder="Enter client email:" value={value.email} lavel="Client email:" />
        <Input1 onChange={set} name="phone" type="text" placeholder="Enter client phone number:" value={value.phone} lavel="Client phone number:" />
        <Textarea onChange={set} name="address" placeholder="Enter work address:" value={value.address} lavel="Client address:" />
        <Textarea onChange={set} name="requirement" placeholder="Enter client requirement:" value={value.requirement} lavel="Client requirement:" />
        <Input1 onChange={set} name="price" type="number" placeholder="Enter price for client:" value={value.price} lavel="Price for client:" />
        <Input1 onChange={set} name="cupdate" type="text" placeholder="Enter client update:" value={value.cupdate} lavel="Client update:" />
        <Input1 onChange={set} name="contactDate" type="date" placeholder="Enter client contact date:" value={value.contactDate} lavel="Contact date:" />
        <Input1 onChange={set} name="workDate" type="date" placeholder="Enter working date:" value={value.workDate} lavel="Working date:" />
        <Input1 onChange={set} name="addBy" type="text" placeholder="Enter added by:" value={value.addBy} lavel="Added by:" />

        <Button1 click={send} name="Save" />
    </Form1>

  )
}

export default AddTodo;