import '../src/assets/css/style.css';
import './assets/css/custom.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import IsLogIn from './pages/IsLogIn';
import IsLogOut from './pages/IsLogOut';

import LogIn from './pages/Login';
import Home from './pages/Home';
import ViewProfile from './pages/ViewProfile';

import AddWorkeReport from './pages/workReport/AddWorkeReport';
import AllWorkReport from './pages/workReport/AllWorkReport';

import AddTodo from './pages/workTodo/AddTodo';
import AllTodo from './pages/workTodo/AllTodo';
import EditTodo from './pages/workTodo/EditTodo';


import Editor from './components/Editor';





function App() {
  return (
    <Routes>
      
      <Route path='/' element={<Navigate to='/dashboard' />} />

      <Route path='/*' element={<IsLogOut />} >
        <Route path="logIn" element={ <LogIn />} />
      </Route>

      <Route path='/' element={<IsLogIn />} >
        <Route path='/' element={<Layout />} >
          <Route path='dashboard' element={<Home />} />
          <Route path='profile' element={<ViewProfile />} />


          <Route path='addWorkReport' element={<AddWorkeReport />} />
          <Route path='allWorkReport' element={<AllWorkReport />} />
          <Route path='editWorkTodo/:tid' element={<EditTodo />} />

          <Route path='addTodo' element={<AddTodo />} />
          <Route path='allTodo' element={<AllTodo />} />

          <Route path='editor' element={<Editor />} />
        </Route>
      </Route>

    </Routes>
  );
}

export default App;
