import React, { useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import url from '../url';
import user from '../assets/images/user.jpg'

function ViewProfile() {


    const [ info, setInfo] = useState({});
    const { id } = useAuth();

    console.log(info)

    

    useEffect(()=>{


        fetch(`${url}/worker/w/${id}`,{method:'GET',mode:'cors',credentials:'include'}).then((data)=>data.json()).then((data)=>{
          setInfo(data.data);
        });
    
    
      },[id]);

  return (
    <div className=' w-full h-full flex justify-center mt-10 p-2'>

        <div className=' w-96 h-[600px] bg-slate-100 flex flex-col p-4 rounded-md shadow-sm'>
            <div>
                <img className=' w-full rounded-md' src={user} alt="" />
            </div>
            <div className=' w-full mt-10'>
                <span className=' font-bold'>Name: </span><span>{info.name}</span><br />
                <span className=' font-bold'>Email: </span><span>{info.email}</span><br />
                <span className=' font-bold'>Phone: </span><span>{info.phone}</span><br />
                <span className=' font-bold'>Address: </span><span>{info.address}</span>
            </div>
        </div>
    </div>
  )
}

export default ViewProfile