import React, { useState }from 'react'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom';
import SideBarToggler from './SideBarToggler';
import MinSideBarToggler from './MinSideBarToggler';

function Sidebar({bar, mbar}) {

    const [active, setActive] = useState(-1);
    console.log('sidebar')

    const data = [

        {
            title:'Work Todo',
            icon: <i className="fa-solid fa-calendar-check pr-2"></i>,
            links:[
                {name:'All Todo',link:'/allTodo'},
                {name:'Add Todo',link:'/addTodo'},
            ]
        },

        {
            title:'Reports',
            icon: <i className="fa-solid fa-chart-line  pr-2"></i>,
            links:[
                {name:'Work Reports',link:'/allWorkReport'},
                {name:'Add Work Report',link:'/addWorkReport'},
            ]
        },

    ]
  return (
      <>
        {
          (mbar === false)?
          <div className={`${bar} hidden  md:w-1/4 lg:w-1/5 xl:w-2/12 float-left transition-all duration-700 md:ml-0 h-screen  md:flex flex-col `}>
            <Link to='/dashboard'><div className=' py-4 h-screen-2 bg-dark-blue-2 text-center text-2xl text-white overflow-hidden'>EasySheba</div></Link>
            <div className=' h-full bg-slate-50'>
                <NavLink to='/dashboard' className={({isActive})=>isActive? 'pt-4 no-underline hover:text-blue-300 block pl-6 text-blue-300 ':'pt-4 no-underline hover:text-blue-300 block pl-6 text-black '}  ><i className="fa-solid fa-chart-column pr-2"></i> Dashboard </NavLink>
                {
                    data.map((data,index)=>{
                        return(
                            <SideBarToggler key={index} index={index} activeIndex={active} setActive={setActive} data={data} />
                        )
                    })
                }
            </div>
        </div>
       :
       <div className=' w-[calc(0%+56px)] float-left relative transition-all duration-700 h-screen flex flex-col '>
            <Link to='/dashboard'><div className=' w-[calc(0%+56px)] h-screen-2 py-4 bg-dark-blue-2 text-center text-2xl text-white'>ES</div></Link>
            <div className='  w-[calc(0%+56px)] h-full bg-slate-50'>
                <NavLink to='/dashboard' className={({isActive})=>isActive? 'pt-4 no-underline hover:text-blue-300 block pl-4 text-blue-300 ':'pt-4 no-underline hover:text-blue-300 block pl-4 text-black '}  ><i className="fa-solid fa-chart-column pr-2"></i> </NavLink>
                {
                    data.map((data,index)=>{
                        return(
                            <MinSideBarToggler key={index} index={index} activeIndex={active} setActive={setActive} data={data} />
                        )
                    })
                }
            </div>
        </div>
   } 

        <div className=' w-[calc(0%+56px)] md:hidden float-left relative transition-all duration-700 h-screen flex flex-col '>
            <Link to='/dashboard'><div className=' w-[calc(0%+56px)] h-screen-2 py-4 bg-dark-blue-2 text-center text-2xl text-white'>KH</div></Link>
            <div className='  w-[calc(0%+56px)] h-full bg-slate-50'>
                <NavLink to='/dashboard' className={({isActive})=>isActive? 'pt-4 no-underline hover:text-blue-300 block pl-4 text-blue-300 ':'pt-4 no-underline hover:text-blue-300 block pl-4 text-black '}  ><i className="fa-solid fa-chart-column pr-2"></i> </NavLink>
                {
                    data.map((data,index)=>{
                        return(
                            <MinSideBarToggler key={index} index={index} activeIndex={active} setActive={setActive} data={data} />
                        )
                    })
                }
            </div>
        </div>
      </>



  )
}

export default Sidebar