import React from 'react';
import TextEditor from './TextEditor';

function Editor(){
    return(<>
    <div className=' w-full bg-yellow-400 p-10 rounded-md'>
        <TextEditor />
    </div>
    </>)
}

export default Editor;