import { useCookies } from 'react-cookie';

function useAuth(){
    const [cookies] = useCookies(['auth']);
		

    if(cookies.auth === undefined){
        return({status:false, id:null});
    }else{
        return({status:true, id:cookies.auth});
    }
}

export default useAuth;