import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button1 } from '../../components/Button';
import { Form1 } from '../../components/Form';
import { Input1, Textarea } from  '../../components/Input';
import url from '../../url';
import useAuth from '../../hooks/useAuth';
import { useEffect } from 'react';
import { Alert2, AlertContainer } from '../../components/Alert';


function AddWorkeReport() {
  const [value, setValue] = useState({title:'', description:'', note:''});
  const navigate = useNavigate();
  const [alert, setAlert] = useState([]);
  const { id } = useAuth();

  useEffect(()=>{

    setValue({...value,"workerId":id});

  },[id]);

  function set(e){
    setValue({...value, [e.target.name]:e.target.value});
  }

  function send(){
    fetch(`${url}/workReport`,{
      method:"POST",
      body: JSON.stringify(value),
      credentials: 'include'
  }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        navigate(-1);
      }else{
        setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
      }
    });
  }


  return (
    <Form1 title="Work Report">
        <div className= " fixed top-16 right-2 ">
            <AlertContainer>
                {alert}
            </AlertContainer>
        </div>
        <Input1 onChange={set} name="title" type="text" placeholder="Enter work title:" value={value.title} lavel="Work title:" />
        <Textarea onChange={set} name="description" placeholder="Enter work description:" value={value.description} lavel="Work description:" />
        <Textarea onChange={set} name="note" placeholder="Enter work note:" value={value.note} lavel="Work note:" />
        <Button1 click={send} name="Save" />
    </Form1>

  )
}

export default AddWorkeReport;