import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert2, AlertContainer } from '../components/Alert';
import Loader from '../components/Loader';
import url from '../url';

function LogIn(){
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [alert, setAlert] = useState([]);
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate()


    function send(){
        if(email === ''){
            setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message='Email is required.' />]);
        }
        
        if( password === ''){
            setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message='password is required.' />]);
        }

        if(email !=='' & password !== ''){
            setLoader(true);
            fetch(`${url}/worker/login`,{
                method:'POST', 
                body:JSON.stringify({email,password}),
                credentials: 'include',
				mode:'cors'

            }).then((data)=>data.json(data)).then((data)=>{
                setLoader(false);
                console.log(data.data)
                if(data.status === true){
                    document.cookie = `auth = ${data.id} ; max-age=3400; path=/`;
                    navigate('/dashboard');
					
                }else{
                    setAlert((alert)=>[...alert, <Alert2 key={ Date.now()} title="Faild!" message={data.message} />]);
                }
            })
        }
       
    }
    
    return(
        <>
            <div className=" relative w-screen h-screen bg-login bg-center bg-no-repeat bg-cover contrast-150 shadow-2xl flex justify-center items-center">
                <div className= " absolute top-10 right-2">
                    <AlertContainer>
                        {alert}
                    </AlertContainer>
                </div>

                <div className=" lgin w-11/12 md:w-3/5 lg:w-2/4 xl:w-1/3 h-96 mx-auto ">
                <div className=' w-full flex justify-center z-10 absolute top-0 -mt-24 '>
                        {
                            loader?<Loader />:<></>
                        }     
                </div>
                    <div >
                        <h1 className=" text-center text-white text-3xl pb-3 border-b border-white"> EasyShaba</h1>
                    </div>
                    <form className=" flex flex-col justify-center p-3 pt-10 gap-y-2" >
                        <label className=" text-white" htmlFor="email">Email:</label>
                        <input onChange={(e)=> setEmail(e.target.value)} className="bg-slate-100/[.1] p-1 rounded-sm text-white outline-none border-2 border-transparent focus:bg-slate-400/[.4]" type="email" name="email" value={email} id="email"  />
                        <label className=" text-white" htmlFor="password" >Password:</label>
                        <input onChange={(e)=> setPassword(e.target.value)} className="bg-slate-100/[.1] p-1 rounded-sm text-white outline-none border-2 border-transparent focus:bg-slate-400/[.4]"  type="password" name="password" value={password}
                        id="password" />
                        <input onClick={send} className=" w-20 p-1 rounded-sm text-white bg-slate-500 hover:bg-slate-600 cursor-pointer" type="button" value="Log In" />
                    </form>
                </div>
            </div>
        </>
    )
}

export default LogIn;