import React, {  useEffect, useState } from 'react';
import Table, { Tr, Td } from '../../components/Table';
import { Edit } from '../../components/Button';
import Loader from '../../components/Loader';
import url from '../../url'
import useAuth from '../../hooks/useAuth';

function AllTodo() {

const [workReport, setCategory] = useState({data:[],next:{},previous:{}});
const [src, setSrc] = useState('');
const [page, setPage] = useState(1);
const [limit, setLimit] = useState(3);
const [loader, setLoader] = useState(true);

const { id } = useAuth();


useEffect(()=>{
    if(src === ''){
        setLoader(true);
        fetch(`${url}/workTodo/w/${id}?page=${page}&limit=${limit}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            setLoader(false);
            if(data.status === true){
                setCategory(data.result);
            }
        });
    }

    return () => setCategory({data:[],next:{},previous:{}});

},[page,src, limit, id]);

useEffect(()=>{
    if(src !== ''){
        fetch(`${url}/workReport/w/${id}?page=${page}&limit=${limit}&search=${src}`,{mode:'cors',credentials:"include"}).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setCategory(data.result);
            }
        });
    
    }

    return () => setCategory({data:[],next:{},previous:{}});
},[page, limit, src, id]);

function search(e){
    setSrc(e.target.value);
}


  return (<>{
    loader?<div className=' w-full flex justify-center mt-5 '><Loader /></div>:
    <Table to="/addTodo" name="Add Todo" rowNames={["#", "Actions", "Title", "Contact Date", "Contact Status", "Working Date", "Work Status", "Price", "Client Requirement",  "Client Update", "Client Name", "Client Phone", "Client Address",  "Client Email", "Description","Note", "Added By",  "Date"]} page={setPage} limit={limit} setLimit={setLimit} srcVal={src} srcFunc={search} data={workReport} width='w-full'>
                {
                    workReport.data.map(({_id, title, description, note, name, email, phone, address, requirement, price, cupdate, contactDate, workDate, addBy, contactStatus, workStatus, date}, index)=>{
                        const d = new Date(date);
                        const dat = d.toDateString();

                        const dn = new Date();
                        const datn = dn.toDateString();


                        const d1 = new Date(contactDate);
                        const datc = d1.toDateString();

                        const d2 = new Date(workDate);
                        const datw = d2.toDateString();



                        return(
                            <Tr key={index}>
                                <Td>{((workReport.previous.page*workReport.previous.limit)+1)+index}</Td>
                                <Td><div className=' w-10'><Edit to={"/editWorkTodo/"+_id} /></div></Td>
                                <Td ><div className=' w-32'>{title}</div></Td>
                                <Td >
                                    {
                                        (datc === "Thu Jan 01 1970")?<>Not added</> : <div className={`${(datn === datc)?" bg-yellow-300 " :""} w-32 rounded-md`}>{datc}</div>
                                    }
                                    
                                </Td>
                                <Td >
                                    {
                                        (contactStatus === "pending")?<div className=' px-1 -py-1 text-sm rounded-full bg-yellow-300'>pending</div>:<></>
                                    }
                                    {
                                        (contactStatus === "contacted")?<div className=' px-1 -py-1 text-sm rounded-full bg-green-400'>contacted</div>:<></>
                                    }
                                    {
                                        (contactStatus === "cancel")?<div className=' px-1 -py-1 text-sm rounded-full bg-red-400'>cancel</div>:<></>
                                    }

                                      
                                </Td>
                                <Td >
                                    {
                                        (datw === "Thu Jan 01 1970")?<>Not added</> : <div className={`${(datn === datw)?" bg-orange-400 " :""} w-32 rounded-md`}>{datw}</div>
                                    }
                                   
                                </Td>
                                <Td >
                                    {
                                        (workStatus === "not goted")?<div className=' px-1 -py-1 text-sm rounded-full bg-yellow-300'>not_goted</div>:<></>
                                    }
                                    {
                                        (workStatus === "goted")?<div className=' px-1 -py-1 text-sm rounded-full bg-blue-400'>goted</div>:<></>
                                    }
                                    {
                                        (workStatus === "done")?<div className=' px-1 -py-1 text-sm rounded-full bg-green-400'>done</div>:<></>
                                    }
                                    {
                                        (workStatus === "cancel")?<div className=' px-1 -py-1 text-sm rounded-full bg-red-400'>cancel</div>:<></>
                                    }
                                    
                                </Td>
                                <Td ><div className=' w-20'>{price}</div></Td>
                                <Td ><div className=' w-60'>{requirement}</div></Td>
                                <Td ><div className=' w-40'>{cupdate}</div></Td>
                                <Td ><div className=' w-40'>{name}</div></Td>
                                <Td ><div className=' w-32'>{phone}</div></Td>
                                <Td ><div className=' w-60'>{address}</div></Td>
                                <Td ><div className=' w-40'>{email}</div></Td>
                                <Td ><div className=' w-60'>{description}</div></Td>
                                <Td ><div className=' w-60'>{note}</div></Td>
                                <Td ><div className=' w-40'>{addBy}</div></Td>
                                <Td ><div className=' w-40'>{dat}</div></Td>
                            </Tr>
                        );
                    })
                }
                
            </Table>
}</>)
}

export default AllTodo;

